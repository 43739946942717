exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-bulletin-index-js": () => import("./../../../src/templates/bulletin/index.js" /* webpackChunkName: "component---src-templates-bulletin-index-js" */),
  "component---src-templates-careers-index-js": () => import("./../../../src/templates/careers/index.js" /* webpackChunkName: "component---src-templates-careers-index-js" */),
  "component---src-templates-contact-index-js": () => import("./../../../src/templates/contact/index.js" /* webpackChunkName: "component---src-templates-contact-index-js" */),
  "component---src-templates-donate-donate-page-js": () => import("./../../../src/templates/donate/donatePage.js" /* webpackChunkName: "component---src-templates-donate-donate-page-js" */),
  "component---src-templates-donate-index-js": () => import("./../../../src/templates/donate/index.js" /* webpackChunkName: "component---src-templates-donate-index-js" */),
  "component---src-templates-frequently-asked-questions-index-js": () => import("./../../../src/templates/frequently-asked-questions/index.js" /* webpackChunkName: "component---src-templates-frequently-asked-questions-index-js" */),
  "component---src-templates-gallery-gallery-post-index-js": () => import("./../../../src/templates/gallery/galleryPost/index.js" /* webpackChunkName: "component---src-templates-gallery-gallery-post-index-js" */),
  "component---src-templates-gallery-index-js": () => import("./../../../src/templates/gallery/index.js" /* webpackChunkName: "component---src-templates-gallery-index-js" */),
  "component---src-templates-get-involved-index-js": () => import("./../../../src/templates/get-involved/index.js" /* webpackChunkName: "component---src-templates-get-involved-index-js" */),
  "component---src-templates-get-involved-international-volunteer-application-index-js": () => import("./../../../src/templates/get-involved/international-volunteer-application/index.js" /* webpackChunkName: "component---src-templates-get-involved-international-volunteer-application-index-js" */),
  "component---src-templates-government-schemes-index-js": () => import("./../../../src/templates/government-schemes/index.js" /* webpackChunkName: "component---src-templates-government-schemes-index-js" */),
  "component---src-templates-index-page-index-js": () => import("./../../../src/templates/index-page/index.js" /* webpackChunkName: "component---src-templates-index-page-index-js" */),
  "component---src-templates-know-us-index-js": () => import("./../../../src/templates/know-us/index.js" /* webpackChunkName: "component---src-templates-know-us-index-js" */),
  "component---src-templates-media-mentions-index-js": () => import("./../../../src/templates/media-mentions/index.js" /* webpackChunkName: "component---src-templates-media-mentions-index-js" */),
  "component---src-templates-news-index-js": () => import("./../../../src/templates/news/index.js" /* webpackChunkName: "component---src-templates-news-index-js" */),
  "component---src-templates-news-news-post-index-js": () => import("./../../../src/templates/news/newsPost/index.js" /* webpackChunkName: "component---src-templates-news-news-post-index-js" */),
  "component---src-templates-our-donors-index-js": () => import("./../../../src/templates/our-donors/index.js" /* webpackChunkName: "component---src-templates-our-donors-index-js" */),
  "component---src-templates-our-focus-index-js": () => import("./../../../src/templates/our-focus/index.js" /* webpackChunkName: "component---src-templates-our-focus-index-js" */),
  "component---src-templates-projects-index-js": () => import("./../../../src/templates/projects/index.js" /* webpackChunkName: "component---src-templates-projects-index-js" */),
  "component---src-templates-stay-informed-index-js": () => import("./../../../src/templates/stay-informed/index.js" /* webpackChunkName: "component---src-templates-stay-informed-index-js" */),
  "component---src-templates-success-stories-index-js": () => import("./../../../src/templates/success-stories/index.js" /* webpackChunkName: "component---src-templates-success-stories-index-js" */),
  "component---src-templates-success-stories-success-stories-post-index-js": () => import("./../../../src/templates/success-stories/successStoriesPost/index.js" /* webpackChunkName: "component---src-templates-success-stories-success-stories-post-index-js" */),
  "component---src-templates-testimonials-index-js": () => import("./../../../src/templates/testimonials/index.js" /* webpackChunkName: "component---src-templates-testimonials-index-js" */)
}

