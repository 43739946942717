import './src/styles/global.css';

export const onClientEntry = function () {
  // Smooth scroll anchor links on same page
  window.onload = function () {
    // // Check if the current URL has an anchor link
    // if (window.location.hash) {
    //   // Get the target element by the hash value in the URL
    //   const targetElement = document.getElementById(window.location.hash.substring(1));
    //
    //   // If the target element exists, scroll to it
    //   if (targetElement) {
    //     targetElement.scrollIntoView({
    //       behavior: "smooth"
    //     });
    //   }
    // }
    //
    // document.querySelectorAll('a').forEach(anchor => {
    //   anchor.addEventListener('click', function (e) {
    //     const href = this.getAttribute('href');
    //     if (window.location.pathname === "/") {
    //       return;
    //     }
    //     if (href?.indexOf(window.location.pathname) > -1 && href?.indexOf("#") > -1) {
    //       e.preventDefault();
    //       const anchorLink = "#" + href.split("#")[1];
    //       document.querySelector(anchorLink).scrollIntoView({
    //         behavior: 'smooth'
    //       });
    //     }
    //   });
    // });
  };
}


